<template>
  <div class="register center-float-center pt-30 mt-30">
    <el-empty>
      <template slot="description"><h3 class="t-primary">正在建设中...</h3></template>
      <el-button size="large" type="primary" @click="back">返回首页</el-button>
    </el-empty>
    
  </div>
</template>
<script>
export default{
  data() {
    return{}

  },
  mounted() {
  },
  methods:{
    back() {
      this.$router.push('/')
    }
  }
}
</script>
<style>
</style>
